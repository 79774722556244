<template>
  <div class="final-g11">
    <my-search-bar>
      <el-form inline>
        <el-form-item>
          <el-button @click="handleSave">保存</el-button>
          <el-button @click="handlePrev">上一页</el-button>
          <el-button @click="handleNext">下一页</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div ref="excelContainer" class="spread-container">
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import axios from 'axios'
import config from '@/config'
import auth from '@/common/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'FinalG11',
  components: { MySearchBar },
  data () {
    return {
      pageSize: 500,
      pageIndex: 1,
      monthCount: 12
    }
  },
  computed: {
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      approvalUserGuid: 'getApprovalUserGuid',
      year: 'getYear',
      month: 'getMonth'
    })
  },
  methods: {
    async handleSave () {
      this.$loading({})

      // 处理数据
      const sheet = this.workbook.getActiveSheet()

      const jsonData = []
      const rowCount = sheet.getRowCount()
      const columnIndexMap = {
        meterageListGuid: 1,
        hasData: 2,
        isValid: 3,
        currentMonthNum: 16,
        totalMonthNum: 19
      }

      for (let rowIndex = 2; rowIndex < rowCount; ++rowIndex) {
        const item = {
          meterageListGuid: sheet.getCell(rowIndex, columnIndexMap.meterageListGuid).value(),
          hasData: parseInt(sheet.getCell(rowIndex, columnIndexMap.hasData).value()),
          isValid: parseInt(sheet.getCell(rowIndex, columnIndexMap.isValid).value()),
          tenderGuid: this.tenderGuid,
          year: this.year,
          month: this.month,
          planStatExaminationGuid: this.planStatExaminationGuid,
          currentMonthNum: parseFloat(sheet.getCell(rowIndex, columnIndexMap.currentMonthNum).value()),
          totalMonthNum: parseFloat(sheet.getCell(rowIndex, columnIndexMap.totalMonthNum).value())
        }
        jsonData.push(item)
      }

      this.$confirm(`当前共有数据${jsonData.length}条，其中有效数据${jsonData.filter(item => item.isValid).length}条，出错数据${jsonData.filter(item => item.hasData && !item.isValid).length}条,未填写数据${jsonData.filter(item => !item.hasData).length}条。确认要提交吗？`, '确认')
        .then(async () => {
          const response = await axios.post(`${config.restHost}/finalG11/${this.tenderGuid}/${this.year}/batchAdd`, jsonData.filter(item => item.isValid), {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`
            }
          })
          console.log(response.data)
          this.$message.success('操作成功')
          this.$loading({}).close()
          await this.init()
        }).catch(() => {
          this.$loading({}).close()
        })
    },
    async handlePrev () {
      this.pageIndex = this.pageIndex - 1 > 0 ? this.pageIndex - 1 : 1
      await this.init()
    },
    async handleNext () {
      this.pageIndex++
      await this.init()
    },
    async init () {
      this.$loading({})

      // 加载excel模版
      const excelTemplate = await axios.get(`/excel/g11-template-4-years.xlsx`, {
        responseType: 'blob'
      })
      this.excelIo = new GC.Spread.Excel.IO()
      this.excelIo.open(excelTemplate.data, json => {
        if (!this.workbook) {
          this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
        }
        this.workbook.fromJSON(json)
      })

      this.defaultLists = (await axios.get(`${config.restHost}/finalG11/defaultLists`, {
        params: {
          tenderGuid: auth.getUserInfo().tenderGuid,
          year: this.year,
          month: this.month,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })).data

      const array = this.defaultLists.map(defaultList => [
        defaultList.meterageDisplayCode,
        defaultList.meterageFullName,
        defaultList.unit,
        defaultList.price,
        defaultList.pictureNum,
        defaultList.pictureNum,
        undefined,
        defaultList.pictureAmount,
        defaultList.pictureAmount,
        defaultList.currentYearNum,
        defaultList.currentYearAmount,
        defaultList.currentYearPercent
      ])

      const hiddenArray = this.defaultLists.map(defaultList => [defaultList.prevMonthNum, defaultList.meterageListGuid])

      this.workbook.getActiveSheet().setRowCount(this.defaultLists.length + 2)
      this.workbook.getActiveSheet().setArray(2, 4, array)
      this.workbook.getActiveSheet().setArray(2, 0, hiddenArray)
      // 设置剪贴板模式
      this.workbook.getActiveSheet().options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values

      this.$loading({}).close()
    }
  },
  async mounted () {
    await this.init()
  }
}
</script>

<style scoped lang="scss">
.final-g11 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .spread-container {
    flex: 1;
  }
}
</style>
